import _ from 'lodash';
import '../styles/main.less';

import 'jquery';

// scripts that are copied from the libs folder.
// This is done via webpack copy plugin in webpack.common.js
// the files are copied into the "fromLibs" folder.
// import '../scripts/fromLibs/jquery.scrollTo.min.js';

//Bootstrap
import 'bootstrap';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/index';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip';
// import 'bootstrap/js/dist/util';

// Bob scripts form Libs 
import '../scripts/fromLibs/ajaxform.js';
import '../scripts/fromLibs/activenav.js'
import '../scripts/fromLibs/ajaxform.js'
import '../scripts/fromLibs/anchorhelper.js'
import '../scripts/fromLibs/bottomize.js'
import '../scripts/fromLibs/flipper.js'
import '../scripts/fromLibs/inviewport.js'
import '../scripts/fromLibs/jumbo.js'
import '../scripts/fromLibs/lazyload.js'
import '../scripts/fromLibs/menuhelper.js'
import '../scripts/fromLibs/modal.js'
import '../scripts/fromLibs/preload.js'
import '../scripts/fromLibs/privacy.js'
import '../scripts/fromLibs/spinner.js'
import '../scripts/fromLibs/unbreak.js'

// import '../scripts/fromLibs/bobframe4.js';
// import '../scripts/fromLibs/bobmsg2.js';
// import '../scripts/fromLibs/bottomize.js';
// import '../scripts/fromLibs/diga_access.js';
// import '../scripts/fromLibs/fadePage.js';
// import '../scripts/fromLibs/glyphicons.js';
// import '../scripts/fromLibs/linkTap.js';
// import '../scripts/fromLibs/md_buttons.js';
// import '../scripts/fromLibs/md_images.js';
// import '../scripts/fromLibs/scrollTo_anchors.js';
// import '../scripts/fromLibs/scrollTop_arrow.js';
// import '../scripts/fromLibs/spinner.js';
// import '../scripts/fromLibs/util.js';

// Project specific scripts
// import '../scripts/accessform.js';
// import '../scripts/carousel.js';
// import '../scripts/externalform.js';
import '../scripts/jquery.scrollTo.min.js';

// import '../scripts/mail_access.js';
// import '../scripts/survey.js';
import '../scripts/main.js';