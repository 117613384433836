(function ($) {

  $.fn.flippify = function () {

      var hasTransitions = function () {
        var thisBody = document.body || document.documentElement,
          thisStyle = thisBody.style,
          support = thisStyle.transition !== undefined || thisStyle.WebkitTransition !== undefined || thisStyle.MozTransition !== undefined || thisStyle.MsTransition !== undefined || thisStyle.OTransition !== undefined;
        return support;
      };

      if(hasTransitions()) {

        var flip_timer;
        // base values
        var rotate = 1;
        var zindex = 0;
        // animation duration
        var duration = 500;
        // initialization marker
        var init = true;

        // activate last speech item
        $('.speech-wrapper .item').last().addClass('active');
        // activate last flipper item
        $('.flipper-wrapper .item').last().addClass('active').addClass('straight');

        var reset = function () {
          $('.speech-wrapper').css({
            'height': 0
          });
          $('.speech-wrapper .item').each(function () {
            if($(this).outerHeight() > $('.speech-wrapper').outerHeight()) {
              $('.speech-wrapper').css({
                'height': $(this).outerHeight()
              });
            }
          });
        };

        var switchSpeech = function () {
          var curr = $('.speech-wrapper .item.active');
          var next = curr.next('.item');
          if(!next.length) {
            next = $('.speech-wrapper').find('.item').first();
          }
          curr.removeClass('active');
          next.addClass('active');
        };


        var flipStuff = function () {
          var curr = $('.flipper-wrapper .item.active');
          var next = curr.next('.item');
          // behaves different after first interval...
          var zindextime = duration/2;
          if(!next.length) {
            next = $('.flipper-wrapper').find('.item').first();
            if(!init) {
              // ...so treat it different after first interval
              zindextime = duration/4;
            }
            // mark first interval as "done"
            init = false;
          }

          // save current reference for later use
          var then = curr;

          // move up next item
          next.addClass('move');
          curr.removeClass('active');

          setTimeout(function () {
            zindex++;
            // reset all items' z-index
            if(zindex > 20) {
              $('.flipper-wrapper .item').css({
                'z-index': 0
              });
              zindex = 1;
            }
            // place item above last used
            next.css({
              'z-index': zindex
            });
          }, zindextime);

          setTimeout(function () {
            // rotate to 0 degrees
            next.addClass('straight');
            next.removeClass('move');
            next.addClass('active');
          }, duration);

          setTimeout(function () {
            // rotate back to origin
            then.removeClass('straight');
          }, duration/2*3);
        };

        // get actual rotation class, set next one
        var getRotateClass = function () {
          if(rotate > 4) {
            rotate = 1;
          }
          var rotateClass =  'rotate' + rotate;
          rotate++;
          return rotateClass;
        };

        // stop flip timer
        var stopFlippin = function () {
          clearInterval(flip_timer);
        };

        // start flip timer
        var startFlippin = function () {
          stopFlippin();
          flip_timer = setInterval(function () {
            if($('.speech-wrapper').inViewport() || $('.flipper-wrapper').inViewport()) {
              flipStuff();
              switchSpeech();
            }
          }, duration*8);
        };

        // rotate all items
        $('.flipper-wrapper .item').each(function () {
          $(this).addClass(getRotateClass());
        });

        $(document).off('.flipper');
        $(window).off('.flipper');

        $(document).on({
          // stop flipper
          "mousemove.flipper": stopFlippin,
          // start flipper again
          "mouseleave.flipper": startFlippin
        }, '.flipper-wrapper, .speech-wrapper');

        $(window).on('resize.flipper, load.flipper', function () {
          reset();
          startFlippin();
        });

        reset();
        startFlippin();

      }
      else {
        $('.flipper-wrapper').remove();
        $('.speech-wrapper').remove();
      }

  };

}(jQuery));
