(function ($) {

  $.fn.unbreak = function (string) {

    var element = this;

    $('*', 'body')
      .andSelf()
      .contents()
      .filter(function(){
        return this.nodeType === 3;
      })
      .filter(function(){
        // Only match when contains 'string' anywhere in the text
        return this.nodeValue.indexOf(string) !== -1;
      })
      .each(function(){
        var parent = $(this.parentElement);
        if(parent.length > 0) {
          var replacement = parent.html().replace(string, '<nobr>' + string + '</nobr>');
          parent.html(replacement);
        }
      });

    return this;

  };

}(jQuery));
