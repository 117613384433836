(function ($) {

  $.fn.popOut = function () {

    var element = this;

    var togglePopup = function(e, item) {

      e.preventDefault();

      var popover = $(item).next('.popover');

      if($(item).hasClass('active')) {
        $(item).removeClass('active');
        popover.hide();
      }
      else {
        $(element).removeClass('active');
        $('.popover').hide();
        var x = -10 + ($(item)[0].offsetLeft + $(item)[0].offsetWidth) - popover.outerWidth()/2;
        var y = -40 + ($(item)[0].offsetTop + $(item)[0].offsetHeight) - popover.outerHeight();
        popover.css({
          'margin-left': x,
          'margin-top': y
        }).show();

        var clientRects = popover[0].getClientRects();
        if(clientRects[0].left < 0) {
          popover.removeClass('top').addClass('right').css({
            'margin-left': 160 + x,
            'margin-top': 90 + y
          });
        }
        else {
          if(!popover.hasClass('block')) {
            popover.removeClass('right').addClass('top');
          }
        }

        $(item).addClass('active');
        var iframe = popover.find('iframe');
        if(undefined === iframe.attr('src')) {
          iframe.attr('src', iframe.attr('data-src'));
        }
      }

    };

    $(document).off('popout');

    element.on('click.popout', function (e) {
      togglePopup(e, this);
    });

    return this;

  };

}(jQuery));
