(function ($) {

  $.fn.preload = function () {

    var elements = this;

    var activate = function (el) {
      $(el).not('.on').first().each(function () {
        $(this).addClass('on');
        if(undefined !== $(this).attr('data-src')) {
          $(this).attr('src', $(this).attr('data-src'));
          $(this).removeAttr('data-src');
          $(this).on('load', function () {
            activate(el);
          });
        }
      });
    };

    $(window).off('.preload');

    $(window).on('load.preload', function () {
      activate(elements);
    });

    activate(elements);

    return this;

  };

}(jQuery));
