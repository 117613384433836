(function ($) {

  $.fn.modal = function () {

    var element = this;

    // use as method command
    if (arguments.length > 0) {

      // method: show
      if (arguments[0] === 'show' && arguments[1]) {

        // usage: target-element.modal('show', content-container-id)

        $('.modal-msg, .modal-msg .msg-content').hide();

        var copy = $(arguments[1]).clone();
        copy.hide();
        $(element).find('.m-content').html(copy);
        copy.fadeIn();

        $(element).show();

        var event = jQuery.Event("show.modal");
        $(element).trigger(event);

        $('body').addClass('modal-bob-open');
      }

    }

    var modalHide = function (e) {
      var $modal;

      if (e.currentTarget == window.document) {
        // esc
        $('.modal').each(function(i, m){
          if ($(m).css('display') == 'block') {
            $modal = $(m);
          }
        });
      } else {
        // button
        var $currentTarget = $(e.currentTarget);
        $modal = $($currentTarget.parents('.modal')[0]);
      }

      var event = $.Event('hide.bob.modal');
      $modal.trigger(event);
      $modal.fadeOut(400, function() {
        $(this).find('.m-content').html('');
        var event = $.Event('afterhide.bob.modal');
        $modal.trigger(event);
      });
      $('body').removeClass('modal-bob-open');
    };

    var modalShow = function (e, item) {

      e.preventDefault();

      $('.modal-msg, .modal-msg .msg-content').hide();

      var src = $(item).attr('href');
      
      if (src) {

        var target = $(item).attr('data-target');
        $('.' + target + ' .m-content').html('');

        if (src.substr(0,1) === '#') {

          var copy = $(src).clone();
          var copyid = copy.attr('id');
          if (copyid) {
            copy.attr('id', copyid + '-mod');
          }
          // IE bug: when cloning, placeholder text is inserted as value
          // Fix: if placeholder equals value, remove value
          $('textarea', copy).each(function(i, ta) {
            if ($(ta).attr('placeholder') == $(ta).val()) {
              $(ta).val('');
            }
          }); // end IE bugfix
          copy.hide();
          $('.' + target + ' .m-content').html(copy);
          copy.fadeIn();

        } else {

          $.ajax({
            url: src,
            cache: false,
            success: function (response) {
              var form = $(response);
              form.hide();
              $('.' + target + ' .m-content').html(form);
              form.fadeIn();
            }
          });
          
        }

        $('.' + target).show();

        var event = jQuery.Event("show.modal");
        $('.' + target).trigger(event);

        $('body').addClass('modal-bob-open');
        
      }

    };

    // use to initialise
    if (arguments.length === 0) {

      $(document).off('.modal');

      element.on('click.modal', function(e) {
        if($(this).attr('href') && !($(this).attr('href').indexOf('mailto') > -1)) {
          e.preventDefault();
          modalShow(e, this);
        }
      });

      $(document).on('click.modal', '.modal-close', function(e) {
        modalHide(e);
      });

      $(document).on('keydown.modal', function (e) {
        e = e || window.event;
        if(e.keyCode === 27) {
          modalHide(e);
        }
      });

      $('.modal').on('scroll.modal', function () {
        var mtop = $('.m-window', this).offset().top;
        var ctop = (window.pageYOffset - mtop);
        if (ctop > 0) {
          $('.m-close', this).css('top', ctop + 'px');
        } else {
          $('.m-close', this).css('top', '0px');
        }
      });

    }

    return this;

  };

}(jQuery));
