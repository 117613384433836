(function ($) {

  $.fn.activenav = function () {

    var element = this;
    if (element.length === 0) return this;

    var setwidth = function() {

      element.css('display', 'table');
      element.css('width', 'auto');
      var initwidth = element.width();

      var winwidth = $(window).outerWidth();
      var logowidth = $('.nav-logo').width();
      var elwidth = winwidth - logowidth - 80;
      if (initwidth > 0 && initwidth < elwidth) {
        elwidth = initwidth;
      }
      element.css('display', 'block');
      element.css('width', elwidth+'px');

    };

    $(window).off('.activenav');
    $(window).on('resize.activenav, load.activenav', function() {
      setwidth();
    });

    return this;

  };

}(jQuery));
