(function ($) {

  $.fn.menuhelper = function () {

    var element = this;

    var file = $('body').attr('data-file');

    if(file) {

      $('a', element).each(function () {
        if($(this).attr('href') === file) {
          $(this).addClass('active');
          var itemclass = 'navbar-active-item';
          $('.navbar-toggle').prepend('<span class="' + itemclass + '"></span>');
          $('.' + itemclass).html($(this).html());
        }
      });

    }

    return this;

  };

}(jQuery));
