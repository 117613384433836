(function ($) {

  $.fn.jumbotronify = function (options) {

    var config = {
      percent: 90,
      offset: 50
    };

    if(options) {
      $.extend(config, options);
    }

    var element = this;

    // adjust the height of the jumbotron
    var adjustJumbo = function () {
      var wh = $(window).outerHeight();
      var h_jumbo = ((wh - config.offset) * config.percent/100);
      $(element).css({
        'height': h_jumbo
      });
    };

    $(window).off('.jumbotron');

    $(window).on('resize.jumbotron, load.jumbotron', function () {
      adjustJumbo();
    });

    adjustJumbo();

  };

}(jQuery));
