// checks, if element is visible AND in visible area
(function ($) {

  $.fn.inViewport = function () {

    if($.support.opacity == false) {
      // IE8 and IE7 bullshit code
      return true;
    }

    if(false === (this.filter(':visible').length > 0)) {
      return false;
    }
    var top = this.offset().top;
    var left = this.offset().left;
    var height = this.height();
    var width = this.width();
    return (
      (
        (top) >= window.pageYOffset &&
        (left) >= window.pageXOffset &&
        (top) <= (window.pageYOffset + window.innerHeight) &&
        (left) <= (window.pageXOffset + window.innerWidth)
      ) || (
        (top + height) >= window.pageYOffset &&
        (left + width) >= window.pageXOffset &&
        (top + height) <= (window.pageYOffset + window.innerHeight) &&
        (left + width) <= (window.pageXOffset + window.innerWidth)
      )
    );

  };

}(jQuery));
