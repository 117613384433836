(function ($) {

  $.fn.lazyLoad = function () {

    var elements = this;

    var activateItems = function (el) {
      $(el).not('.on').each(function () {
        var item = this;
        activate(item);
        $(item).load(function () {
          activate(item);
        });
      });
    };

    var activate = function (el) {
      // doesn't recognize items larger than screen...
      if(
        $(el).inViewport() ||
          (
            ($(el).position().top > $(window).scrollTop()) &&
            ($(el).position().top < $(window).scrollTop() + $(window).outerHeight())
          )
        )
      {
        $(el).addClass('on');
        if(undefined !== $(el).attr('data-src')) {
          $(el).attr('src', $(el).attr('data-src'));
          $(el).removeAttr('data-src');
        }
      }
    }

    $(window).off('.lazyLoad');

    $(window).on('scroll.lazyLoad, resize.lazyLoad, load.lazyLoad', function () {
      activateItems(elements);
    });

    activateItems(elements);

    return this;

  };

}(jQuery));
