(function ($) {

  $.fn.ajaxForm = function () {

    var msg = null;

    var successTimeout = null;

    var modalMsgHide = function (duration) {
      if(!duration) {
        duration = 0;
      }
      $('.modal-msg').fadeOut(duration, function () {
        $('.modal-msg .msg-content').hide();
      });
      msg = null;
    };

    var modalMsgSuccess = function () {
      msg = 'success';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-success').show();
      $('.modal-msg').fadeIn();
      successTimeout = window.setTimeout(modalMsgHide, 3000, 300);
      modalHide();
    };

    var modalHide = function () {
      e = $.Event('hide.bob.modal');
      $('.modal-form-context.modal').trigger(e);
      $('.modal-form-context.modal').fadeOut(400, function() {
        $(this).find('.modal-form-context .m-content').html('');
      });
      $('body').removeClass('modal-bob-open');
    };

    var modalMsgError = function () {
      msg = 'error';
      clearTimeout(successTimeout);
      $('.modal-msg .msg-error').show();
      $('.modal-msg').fadeIn();
    };

    var sendData = function (e) {

      e.preventDefault();

      $('.form-group', '.modal').removeClass('has-error');

      modalMsgHide();

      $('#submit', '.modal form.ajax').prop('disabled', true);

      var data = {};
      $(':input', e.target).each(function() {
        data[this.name] = $(this).val();
      });

      var dataEvent = $('.modal form.ajax').data('event');
      // if (!dataEvent) {
      //   dataEvent = 'conversionSuccess';
      // }
      // console.log('dataEvent', dataEvent);

      $('document').spinner('show');

      $.ajax({
        url: "/zen",
        type: "POST",
        data: data,
        cache: false,
        success: function (response) {
          // show message
          modalMsgSuccess();

          // tracking by google tag manager
          if (window.dataLayer !== undefined && dataEvent) {
            window.dataLayer.push({"event": dataEvent});
          }

          $('document').spinner('hide');
        },
        error: function (xhr, b, x) {
          var responseText = xhr.responseText;
          if(responseText && responseText.indexOf('{"error":"invalid email"}') > -1) {
            $('.form-control.email', '.modal').parent('.form-group').addClass('has-error');
          }
          else if(responseText && responseText.indexOf('{"error":"RecordInvalid"}') > -1) {
            $('.form-control.name', '.modal').parent('.form-group').addClass('has-error');
            $('.form-control.text', '.modal').parent('.form-group').addClass('has-error');
          }
          else {
            modalMsgError();
          }
          // enable button again
          $('#submit', '.modal form.ajax').prop('disabled', false);
          $('document').spinner('hide');
        }
      });

    };

    $('.modal').on('hide.bob.modal', function() {
      if (msg === 'error') {
        modalMsgHide(300);
      }
    });

    $(document).off('.ajaxform');

    $(document).on('submit.ajaxform', 'form.ajax', function (e) {
      sendData(e);
    });

    $(document).on('click.ajaxform', '.modal-msg', function () {
      modalMsgHide(300);
    });

    return this;

  };

}(jQuery));
