(function ($) {

  $.fn.anchorhelper = function () {

    var element = this;

    var file = $('body').attr('data-file');

    if(file) {

      var getItems = function () {
        var items = ['#top'];
        if ($('#spots').length > 0) {
          items.push('#spots');
        }
        var links = $('.footer ul li a[href^="' + file + '"]');
        links.each(function () {
          var txt = $(this).attr('href');
          txt = txt.replace(file, '');
          items.push(txt);
        });
        items.push('#bottom');
        items.push('#eop');
        return items;
      };

      var getNext = function (reverse) {
        var items = getItems();
        var next = '#top';
        for(var i=0; i < items.length; i++) {
          if ($(items[i]).length > 0) {
            var top = Math.round($(items[i]).position().top);
            if(reverse) {
              if(top < $(window).scrollTop()) {
                // don't break here
                next = items[i];
              }
            }
            else {
              if(top > $(window).scrollTop() + 10) {
                // break each
                return items[i];
              }
            }
          }
        }
        return next;
      };

      var setAnchorHelper = function() {
        var next = getNext();
        var prev = getNext(true);
        $('#anchor-helper a.prev').attr('href', file + prev);
        $('#anchor-helper a.next').attr('href', file + next);
      };

      setAnchorHelper();

      $(window).off('.anchor-helper');

      $(window).on('scroll.anchor-helper', function () {
        setAnchorHelper();
      });

    }

    return this;

  };

}(jQuery));
