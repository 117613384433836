(function ($) {

  $.fn.bottomize = function () {

    var element = this;
    var diff;

    var setMargin = function () {
      var bottom = parseInt($(element).outerHeight(), 10);
      if ($('.body-content').length > 0) {
        diff = $(window).outerHeight() - $('.body-content').outerHeight() + bottom;
      } else {
        diff = $(window).outerHeight() - $('body').outerHeight() + bottom;
      }
      if(diff > 0) {
        $(element).css({
          'height': diff
        });
      } else {
        $(element).css({
          'height': 0
        });
      }
    };

    $(window).off('.bottomize');

    $(window).on('resize.bottomize, load.bottomize, contentchange.bottomize', function () {
      setMargin();
    });

    // setMargin();

    return this;

  };

}(jQuery));
